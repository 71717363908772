import { useCallback } from 'react';
import { fromArrayBuffer } from 'geotiff';
import proj4 from 'proj4';

export const useGeoTiffProcessor = () => {
  const processGeoTiff = useCallback(async (arrayBuffer) => {
    const geoTiff = await fromArrayBuffer(arrayBuffer);
    const image = await geoTiff.getImage();

    const tiepoint = image.getTiePoints()[0];
    const pixelScale = image.getFileDirectory().ModelPixelScale;
    const modelTiepoint = [
      tiepoint.i, tiepoint.j, tiepoint.k,
      tiepoint.x, tiepoint.y, tiepoint.z
    ];

    const utm10n = "+proj=utm +zone=10 +datum=WGS84 +units=m +no_defs";
    const wgs84 = "+proj=longlat +datum=WGS84 +no_defs";

    const [px, py, pz, gx, gy, gz] = modelTiepoint;
    const [scaleX, scaleY] = pixelScale;
    const scaledY = -scaleY;

    const pixelToLatLng = (pixelX, pixelY) => {
      const projectedX = gx + pixelX * scaleX;
      const projectedY = gy + pixelY * scaledY;
      const [lon, lat] = proj4(utm10n, wgs84, [projectedX, projectedY]);
      return [lat, lon];
    };

    const width = image.getWidth();
    const height = image.getHeight();
    const rasterData = await image.readRasters();

    const validValues = rasterData[0].filter(value => value > 0);
    const minValue = Math.min(...validValues);
    const maxValue = Math.max(...validValues);

    const logMin = Math.log10(minValue);
    const logMax = Math.log10(maxValue);

    const normalizeLog = (value) => {
      const logValue = Math.log10(value);
      return (logValue - logMin) / (logMax - logMin);
    };

    const heatmapPoints = [];

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const value = rasterData[0][y * width + x];
        if (value > 0) {
          const intensity = normalizeLog(value);
          const [lat, lon] = pixelToLatLng(x, y);
          heatmapPoints.push([lat, lon, intensity]);
        }
      }
    }

    const firstPoint = pixelToLatLng(0, 0);
    return { heatmapPoints, firstPoint };
  }, []);

  return { processGeoTiff };
};
