import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FileUpload } from '../file_upload/FileUpload';
import { useGeoTiffProcessor } from '../../hooks/useGeoTiffProcessor';
import L from 'leaflet';
import 'leaflet.heat';

import {useKeycloak} from "@react-keycloak/web";
import { fetchAPI } from '../../util/Axios_Helper';
import axios from 'axios';


// HeatmapLayer
const HeatmapLayer = ({ data }) => {
  const map = useMap();
  
  React.useEffect(() => {
    if (!data) return;

    const heatLayer = L.heatLayer(data, {
      radius: 10,
      blur: 7,
      maxZoom: 17,
      gradient: {
        0.0: 'blue',
        0.5: 'yellow',
        1.0: 'red'
      }
    });

    heatLayer.addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, data]);

  return null;
};

// Main Map Component
export const MapComponent = () => {
  const [heatmapData, setHeatmapData] = useState(null);
  const { processGeoTiff } = useGeoTiffProcessor();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { keycloak } = useKeycloak();
	const accessToken=keycloak.token;

  useEffect(() => {
    fetchGeoTiffData();
  }, [])
//Instead of handling the input with file upload from user side, we fetch from the server
  // const handleFileUpload = async (file) => {
  //   const { heatmapPoints, firstPoint } = await processGeoTiff(file);
  //   setHeatmapData(heatmapPoints);
    
    
  // };

  useEffect(() => {
    if (keycloak.authenticated) {
      fetchGeoTiffData();
    }
  }, [keycloak.authenticated]);

  const fetchGeoTiffData = async () => {
    if (!keycloak.token) {
      setError('No authentication token available');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/data/file/forecastTiff', {
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
        responseType: 'arraybuffer'
      });

      const { heatmapPoints, firstPoint } = await processGeoTiff(response.data);
      setHeatmapData(heatmapPoints);

    } catch (err) {
      setError(err.message);
      console.error('Error fetching GeoTIFF data:', err);
    } finally {
      setIsLoading(false);
    }
  };


  const mapStyle = {
    height: 'calc(100vh - 60px)',
    width: '100%',
  };

  if (!keycloak.authenticated) {
    return <div>Please log in</div>;
  }

  if (isLoading) {
    return <div  style={{"margin":"1%"}}> <h4>Loading GeoTIFF data...</h4></div>;
  }

  if (error) {
    return <div>Error loading data: {error}</div>;
  }

  return (
    <div>
      {/* Add this part if we want to upload the tiff file  manually */}
      {/* <FileUpload onFileUpload={handleFileUpload} />
      <hr style={{ margin: '1rem 0' }} /> */}
      <MapContainer
        style={mapStyle}
        center={[48.5, -123.5]}
        zoom={9}
        maxZoom={18}
        minZoom={5}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
        <HeatmapLayer data={heatmapData} />
      </MapContainer>
    </div>
  );
};
